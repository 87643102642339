import React, { Component } from 'react';
import "../../css/bootstrap.min.css";


 export default function Calendar(props) {

    return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="jumbotron card card-block">
                            <h2 className="text-center">
                            This is the Events page
                            </h2>
                            <div className="divider" />
                            <p className="text-justify">
                                <br></br>
                                Blah
                                <br></br><br></br>
                                More Blah
                                <br></br><br></br>
                            </p>
                            <div>
                                <div className="beta_signup"><a className="btn btn-primary btn-large" href="mailto:contactus@myezvalet.com?Subject=Early%20Signup">Signup For Early Access</a></div> 
                                <div className="divider"><br></br></div>
                                <div className="progress_signup"><a className="btn btn-primary btn-large" href="mailto:progress@myezvalet.com?Subject=Keep%20Me%20Updated">Signup For Progress Updates</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
    
}