import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import config from './config-exports.js';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";


Amplify.configure({
    Storage: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
      identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
      endpoints: [
        {
          name: "test",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
      ]
    }
  });

ReactDOM.render(
  <Auth0Provider
    domain="lodech.us.auth0.com"
    clientId="s7yGLuC4AAB343AFudLIhRZKmr8y4XAz"
    redirectUri={window.location.origin}
    audience="https://lodech.us.auth0.com/api/v2/"
    scope="read:current_user update:current_user_metadata"
  >
        <Router>
          <App />
        </Router>
    </Auth0Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
