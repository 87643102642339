import React from 'react';
import LoginButton from '../auth/loginbutton';
import LogoutButton from '../auth/logoutbutton';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom'

const Navbar = (props) => {


    const { isLoading, isAuthenticated } = useAuth0();

    console.log("From Navbar " + isAuthenticated);

    const loginOrAuthed = () => {
        return isAuthenticated ?
            <ul className="navbar-nav ml-md-auto">
                <li className="nav-item active">
                    <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-item nav-link" to="/profile">My Profile</Link>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Events
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="dropdown-item" to="/events/calendar">Event Calendar</Link>
                        <Link className="dropdown-item" to="#">My Events</Link>
                        <Link className="dropdown-item" to="#">My Events Summary</Link>
                    </div>
                </li>
                <li className="nav-item">
                    <LogoutButton />
                </li>
            </ul>
            
        :
            <ul className="navbar-nav ml-md-auto">
                <li className="nav-item active">
                    <LoginButton />
                </li>
            </ul>
                    
    };

    return (
        
                        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
                                <Link className="navbar-brand" to="/">MyEZValet</Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMain" aria-controls="navbarMain" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                            <div className="collapse navbar-collapse " id="navbarMain">
                                {loginOrAuthed()}
                            </div>    
                        </nav>   
        );
}



export default Navbar;