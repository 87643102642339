import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Security = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
        <div className="tab-pane" id="security">
            <h6>SECURITY SETTINGS</h6>
            <hr/>
            <form>
                <div className="form-group">
                    <label className="d-block">Change Password</label>
                    <input type="text" className="form-control" placeholder="Enter your old password"/>
                    <input type="text" className="form-control mt-1" placeholder="New password"/>
                    <input type="text" className="form-control mt-1" placeholder="Confirm new password"/>
                </div>
            </form>
        </div>
    )
  );
};

export default Security;