import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ProfilePic = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
        <div className="profile-pic">
            <button className="circular--portrait center">
            <img src={user.picture} alt={user.name} className="circular--portrait center"/>
            </button>
        </div>
    )
  );
};

export default ProfilePic;