import React from 'react';
import Navbar from './components/navbar/navbar.js'
import Routes from './routes.js';
import './css/main.css';



function App(props) {

  return (
    <div className="container-fluid">
      
        <div className="row">
          <div className="col-md-12">
            <Navbar />
            <div className="body">
            <Routes />
            </div>
            
          </div>
        </div>
    </div>
  );
  
}

export default App;

