import React from "react";
import { Redirect } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import '../../../css/setup.css';
import Profile from "../profile.js";

const ProfileSetup = () => {
  const { user, isAuthenticated } = useAuth0();
  console.log("User has not setup their profile yet.");
  console.log(user);

  return (
    isAuthenticated && (
      user.email === user.name ? (
        <div>
          <div className="alert alert-danger text-center" role="alert">
            You have not finished your profile. Please finish updating your profile.
          </div>
          <Profile />
        </div>
      )
      :
      (
        <Redirect to='/profile' />
      )
    )
  );
};

export default ProfileSetup;