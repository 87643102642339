import React from "react";
import { Route, Switch, Redirect} from "react-router-dom";
import Welcome from '../src/components/apps/welcome.js';
import Index from '../src/components/apps/index.js';
import Calendar from '../src/components/apps/calendar.js';
import Profile from "./components/apps/profile.js";
import ProfileSetup from "./components/apps/setups/setup_profile.js"
import { useAuth0 } from "@auth0/auth0-react";


export default function Routes() {

    const { isAuthenticated, isLoading, user } = useAuth0();


    if (isLoading){
        return (
            <div className="loading">
                <img src="CarKeys_Loading_v1.gif" />
                <div className="h3">LOADING...</div>
            </div>

        );
    }
    
    return (
        isAuthenticated ? (
            user.name === user.email? (
                <Switch>
                    <Route exact path="/setup_profile" component={ProfileSetup} />
                    <Redirect to="/setup_profile#profile" />
                </Switch>
                
            )
            :
            (
                <Switch> 
                    <Route exact path="/" component={Index} />
                    <Route exact path="/setup_profile" component={ProfileSetup} />
                    <Route exact path="/events/calendar" component={Calendar} />
                    <Route exact path="/profile" component={Profile} />
                </Switch>
                
            )
        )
        :
        (
            <Switch>
                <Route exact path="/">
                    <Welcome />
                </Route>
            </Switch>
        )
        
    );
}
