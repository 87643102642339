export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "YOUR_API_GATEWAY_URL"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_rDjjMvI7i",
    APP_CLIENT_ID: "6m5eqbuahtms5cpbjk8ri8bqde",
    IDENTITY_POOL_ID: "arn:aws:cognito-idp:us-east-1:295584765326:userpool/us-east-1_rDjjMvI7i"
  }
};