import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";



export default function Index(props) {

    const { user } = useAuth0();

    const welcomeName = () => {
        console.log(user.name);
        return (
            <h2 className="text-center">
                Welcome {user.name}
            </h2>
        );
    }

    return(
        <div>
            <div className="jumbotron card card-block">
                {welcomeName()}
                <div className="divider" />
                <p className="text-justify">
                    <br></br>
                    We are still under development but will soon be your go-to source for management of your valet events.
                    <br></br><br></br>
                    We urge you to signup for the latest updates we’re making and signup for early access which will give you free access to the application while it’s still under development and a provide you with a very low cost plan once we officially launch. 
                    <br></br><br></br>
                </p>
                <div>
                    <div className="beta_signup"><a className="btn btn-primary btn-large" href="mailto:contactus@myezvalet.com?Subject=Early%20Signup">Signup For Early Access</a></div> 
                    <div className="divider"><br></br></div>
                    <div className="progress_signup"><a className="btn btn-primary btn-large" href="mailto:progress@myezvalet.com?Subject=Keep%20Me%20Updated">Signup For Progress Updates</a></div>
                </div>
            </div>
            <div className="jumbotron card card-block">
                <h4>Dashboards will show up here</h4>
            </div>
        </div>
    );
    
}