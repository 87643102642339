import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import '../../css/setup.css';
import ProfilePic from "./profile/profile-pic.js";
import ProfileMenu from "./profile/profile-menu.js";
import ProfileCards from "./profile/profile-cards.js";

const Profile = () => {
  const { user, isAuthenticated } = useAuth0();
  console.log(user);

  return (
    isAuthenticated && (

      <div className="container">
        <ProfilePic />
        <div className="row gutters-sm">
          <ProfileMenu />
          <div className="col-md-8">
            <ProfileCards />
          </div>
        </div>
      </div>
    )
  );
};

export default Profile;