import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { updateProfile } from "../../../callers/update_user_profile.js";

const Profile = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const domain = "lodech.us.auth0.com";
  const formSubmit = async () => {
      if (validateForm()){
        var fName = document.getElementById('firstName').value;
        var lName = document.getElementById('lastName').value;
        var data = { 
            "given_name": fName,
            "family_name": lName,
            "name": `${fName} ${lName}`,
            "nickname": user.nickname,
            "picture": user.picture 
        }
        // const accessToken = await getAccessTokenSilently({
        //   audience: `https://${domain}/api/v2/`,
        //   scope: "update:current_user_metadata"
        // });
        await updateProfile(data, user);
        window.location.reload(false);
      }
  }

  const resetform = () =>{
    document.getElementById("Profile_Form").reset();
  }

  function validateForm() {
    if (document.forms["Profile_Form"]["firstName"].value == "") {
      alert("First name must be filled out");
      return false;
    }
    if (document.forms["Profile_Form"]["lastName"].value == "") {
        alert("Last name must be filled out");
        return false;
    }
    return true;
  }


  return (
    isAuthenticated && (
         user.name === user.email? (
            <div className="tab-pane active" id="profile">
                <h6>YOUR PROFILE INFORMATION</h6>
                <hr/>
                <form id="Profile_Form" onSubmit={formSubmit}>
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input type="text" className="form-control" id="firstName" aria-describedby="fullNameHelp" placeholder="Enter your firstname" defaultValue=""/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input type="text" className="form-control" id="lastName" aria-describedby="fullNameHelp" placeholder="Enter your lastname" defaultValue=""/>
                  </div>
                  <hr/>
                  <div className="form-group small text-muted">
                    All of the fields on this page are required, and by filling them out, you're giving us consent to share this data wherever your user profile appears. Should you require a name change, you must contact support.
                  </div>
                  <button type="submit" className="btn btn-primary">Update Profile</button>
                  <button type="reset" className="btn btn-light" onClick={resetform}>Reset Changes</button>
                </form>
            </div>
         )
         :
         (
            <div className="tab-pane active" id="profile">
            <h6>YOUR PROFILE INFORMATION</h6>
            <hr/>
              <div className="form-group">
                <label>Name:  {user.name}</label>
              </div>
            </div>
         )
    )
  );
};


export default Profile;