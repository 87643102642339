import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ProfilePic = () => {
  const { user, isAuthenticated } = useAuth0();

  const emailChange = () => {
    return (
      user.email_verified && (
        <div>
        <hr/>
                  <div className="form-group">
                    <label className="d-block text-danger">Change Email Address</label>
                    <p className="text-muted font-size-sm">You will be required to verify your new email address before the change is complete</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="newemail">New Email Address</label>
                    <input type="text" className="form-control" id="newemail" aria-describedby="emailHelp" placeholder="Enter your new email address" defaultValue=""/>
                  </div>
                  <button className="btn btn-primary" type="button">Change Email</button>
                  </div>
      )
    );
  }

  return (
    isAuthenticated && (
        <div className="tab-pane" id="account">
            <h6>ACCOUNT SETTINGS</h6>
            <hr/>
            <form>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input type="text" className="form-control" id="username" aria-describedby="usernameHelp" placeholder="Enter your username" defaultValue={user.nickname}/>
                    <small id="usernameHelp" className="form-text text-muted">Your username identifies you allowing limited access to your personal details.</small>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input type="text" className="form-control" id="email" aria-describedby="emailHelp" readOnly defaultValue={user.email}/>
                    <small id="usernameHelp" className="form-text text-muted">Your email must be verified before you can change it.</small>
                </div>
                {emailChange()}
                <hr/>
                <div className="form-group">
                    <label className="d-block text-danger">Delete Account</label>
                    <p className="text-muted font-size-sm">Once you delete your account, there is no going back. Please be certain.</p>
                </div>
                <button className="btn btn-danger" type="button">Delete Account</button>
            </form>
        </div>
    )
  );
};

export default ProfilePic;
